import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Logosap from '../../img/logo_gna.png'
import { Context } from '../../store/AppContext'
import { BASE_URL } from '../../utils'
import axios from 'axios'
import './Login.css'

const Login = () => {
    const {setUser} = useContext(Context)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [cargando, setCargando] = useState(false)
    const navigate = useNavigate()

    const login = (e) => {
        e.preventDefault()
        setError('')

        if(email === '' || password === '') {
            return setError('Todos los campos son obligatorios')
        }

        setCargando(true)

        const datos = {
            email,
            password
        }

        try {
            axios.post(`${BASE_URL}/usuarios/login`, datos).then((response) => {
                if(response.data.error) {
                    setCargando(false)
                    setError(response.data.msg)
                    return
                }
                localStorage.setItem('usuario', response.data.token)
                setUser({
                    id: response.data.usuario.id,
                    usuario: response.data.usuario.usuario,
                    tipo_usuario: response.data.usuario.tipo_usuario,
                    nombre: response.data.usuario.nombre,
                    sector: response.data.usuario.sector,
                    token: response.data.token
                })
                navigate('/')
            });
        } catch (error) {
            setCargando(false)
            setError('Ha ocurrido un error')
        }
    }

    // const ingresar = (e) => {
    //     e.preventDefault();

    //     console.log(usuario)

    //     if(pass !== "1234") {
    //         setError('Usuario o contraseña incorrecta')
    //     } else if(usuario === "agruben@dioxitek.com.ar") {
    //         setUser({
    //             user: "Analia Gruben",
    //             tipo: 'admin'
    //         })
    //         navigate('/home')
    //     } else if(usuario === "ncevela@dioxitek.com.ar") {
    //         setUser({
    //             user: "Nuria Cevela",
    //             tipo: 'otro'
    //         })
    //         navigate('/home')
    //     }else {
    //         setUser({
    //             user: usuario === "cesar.val@gnaconsultores.com.ar" ? "Cesar Val Leiva" : usuario === "diego.rissi@gnaconsultores.com.ar" ? "Diego Rissi" : "Javier Pasalagua",
    //             tipo: 'master'
    //         })
    //         navigate('/home')
    //     }
    // }

  return (
    <div className='login'>
        <form className='ingresar shadow' onSubmit={login}>
            <div className="logo">
                <img src={Logosap} alt="SAP B1" />
            </div>
            {
                error !== '' ?
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                : ''
            }
            <div className="mb-3">
                <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Usuario' />
            </div>
            <div className="mb-3">
                <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Contraseña' />
            </div>
            {/* <div className='mb-3 olvidemicontrasena'>
                <Link to="/">Olvidé mi contraseña</Link>
            </div> */}
            <div className="d-grid gap-2">
                <button className="btn btn-login" type="submit">
                    {
                        cargando ? 
                            <div className="spinner-border spinner-grow-sm text-light" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        :
                            <p className="textoIngresar">Ingresar</p>
                    }
                </button>
            </div>
        </form>
    </div>
  )
}

export default Login

/*
Relevamientos
Blueprints
Pruebas Unitarias
Capacitaciones
Agenda
Integrantes
*/
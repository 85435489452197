import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '../../store/AppContext'
import Swal from 'sweetalert2';
import './MenuSuperior.css'

const MenuSuperior = () => {
    const {user, setUser, handleMenu} = useContext(Context)

    const navigate = useNavigate()

    const cerrarSesion = () => {
        Swal.fire({
            title: 'Cerrar sesión?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar'
          }).then((result) => {
            if (result.isConfirmed) {
                setUser({})
                localStorage.removeItem('usuario');
                navigate('/')
            }
          })
    }

  return (
    <div className='menuSuperior shadow-sm'>
        <i className="bi bi-list" onClick={() => handleMenu()}></i>
        <div className="derechaMenuSuperior">
            {/* <p>{user.user}</p> */}
            <p>{user.nombre}</p>
            <i className="bi bi-power cerrarSesion" onClick={cerrarSesion}></i>
        </div>
    </div>
  )
}

export default MenuSuperior
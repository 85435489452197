import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Login from './pages/Login/Login';
import Home from './pages/Home';
import './App.css';
import Sidebar from './components/Sidebar/Sidebar';
import MenuSuperior from './components/MenuSuperior';
import Relevamientos from './pages/Relevamientos';
import Equipo from './pages/Equipo';
import Blueprints from './pages/blueprints/Blueprints';
import Casosdeprueba from './pages/Casosdeprueba/Casosdeprueba';
import Etapas from './pages/Etapas/Etapas';
import Minutas from './pages/Minutas/Minutas';
import Interfaz from './pages/Interfaz';

function App() {
  const {pathname} = useLocation()
  const user = localStorage.getItem('usuario')

  return (
    <div className='d-flex'>
      {pathname !== '/' ? <Sidebar /> : ''}
      <div className='contenidoPlataforma'>
        {pathname !== '/' ? <MenuSuperior /> : ''}
        <Routes>
          <Route path="/" element={user !== null ? <Navigate to="/home" /> : <Login />} />
          <Route path="/home" element={user !== null ? <Home /> : <Navigate to="/" />} />
          {/* <Route path="/interfaz" element={user !== null ? <Interfaz /> : <Navigate to="/" />} /> */}
          <Route path="/equipo" element={user !== null ? <Equipo /> : <Navigate to="/" />} />
          <Route path="/minutas" element={user !== null ? <Minutas /> : <Navigate to="/" />} />
          <Route path="/relevamientos" element={user !== null ? <Relevamientos /> : <Navigate to="/" />} />
          <Route path="/blueprints" element={user !== null ? <Blueprints /> : <Navigate to="/" />} />
          <Route path="/pruebas" element={user !== null ? <Casosdeprueba /> : <Navigate to="/" />} />
          <Route path="/etapas" element={user !== null ? <Etapas /> : <Navigate to="/" />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;

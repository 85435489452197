import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Logogenea from '../../img/logo_gna.png'
import { Context } from '../../store/AppContext'
import Menu from '../Menu'
import './Sidebar.css'

const Sidebar = () => {
  const {sideBar, handleMenu} = useContext(Context)
  return (
    <>
        <div className={`contenedorSidebar ${sideBar ? "" : "closeContenedorSidebar"}`}>
            <div className="cabeceraSidebar">
              <div className='contenedorImgLogo'>
                <Link to="/home"><img src={Logogenea} alt="logo" /></Link>
              </div>
            </div>
            <div className="menuSidebar">
              <Menu />
              {/* {menuSwitch(user.tipo_usuario)} */}
            </div>
        </div>
        <div className={`${sideBar ? "" : "fondoNegroSidebar"}`} onClick={handleMenu}></div>
    </>
  )
}

export default Sidebar
import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { Context } from '../../store/AppContext'
import axios from 'axios'
import moment from 'moment';
import { BASE_URL } from '../../utils'
import toast, { Toaster } from 'react-hot-toast';
import './Blueprints.css'

const Blueprints = () => {
    const {user} = useContext(Context)
    const [blueprints, setBlueprints] = useState([])
    const [re, setRe] = useState([])
    const [cargando, setCargando] = useState(true)
    const [fecha, setFecha] = useState('')
    const [link, setLink] = useState('')
    const [sectores, setSectores] = useState([])
    const [sector, setSector] = useState('')
    const token = localStorage.getItem('usuario')

    const obtenerBlueprints = async () => {
        const response = await axios.get(`${BASE_URL}/blueprints`)
        setBlueprints(response.data)
        setCargando(false)
    }

    const obtenerSectores = async () => {
        const response = await axios.get(`${BASE_URL}/sectores`)
        setSectores(response.data)
    }

    const chequearUsuario = () => {
        switch (user.usuario) {
            case "abortolotti@dioxitek.com.ar":
                setRe(blueprints.filter(rele => rele.sector === "Contabilidad" || rele.sector === "Presupuesto"))
                break;
            case "pgonzalez@dioxitek.com.ar":
                setRe(blueprints.filter(rele => rele.sector === "Contabilidad"))
                break;
            case "sisasi@dioxitek.com.ar":
                setRe(blueprints.filter(rele => rele.sector === "Presupuesto"))
                break;
            // case "ncevela@dioxitek.com.ar":
            //     setRe(blueprints.filter(rele => rele.sector === "Compras" || rele.sector === "Tesorería/Finanzas" || rele.sector === "Inventario y Patrimonio" || rele.sector === "Facturación" || rele.sector === "Cuentas a Pagar" || rele.sector === "Administración CBA" || rele.sector === "Tesorería CBA" || rele.sector === "Compras CBA"))
            //     break;
            case "cvazquez@dioxitek.com.ar":
                setRe(blueprints.filter(rele => rele.sector === "Compras"))
                break;
            case "dzubiaurre@dioxitek.com.ar":
                setRe(blueprints.filter(rele => rele.sector === "Tesorería/Finanzas"))
                break;
            case "mcardozo@dioxitek.com.ar":
                setRe(blueprints.filter(rele => rele.sector === "Inventario" || rele.sector === "Patrimonio" ))
                break;
            case "nlofreda@dioxitek.com.ar":
                setRe(blueprints.filter(rele => rele.sector === "Facturación"))
                break;
            case "cgarcia@dioxitek.com.ar":
                setRe(blueprints.filter(rele => rele.sector === "Administración CBA" || rele.sector === "Tesorería CBA" || rele.sector === "Compras CBA" || rele.sector === "Compras" || rele.sector === "Tesorería/Finanzas"))
                break;
            case "ssalgado@dioxitek.com.ar":
                setRe(blueprints.filter(rele => rele.sector === "Tesorería CBA" || rele.sector === "Tesorería/Finanzas"))
                break;
            case "amoreno@dioxitek.com.ar":
                setRe(blueprints.filter(rele => rele.sector === "Compras CBA" || rele.sector === "Compras"))
                break;
            case "dberbach@dioxitek.com.ar":
                setRe(blueprints)
                break;
            case "dsureda@dioxitek.com.ar":
                setRe(blueprints)
                break;
            case "pnoguera@dioxitek.com.ar":
                setRe(blueprints)
                break;
            case "lcernadas@dioxitek.com.ar":
                setRe(blueprints)
                break;
            case "ssavarese@dioxitek.com.ar":
                setRe(blueprints)
                break;
            case "cesarvalleiva@gmail.com":
                setRe(blueprints)
                break;
            case "cjimenez@dioxitek.com.ar":
                setRe(blueprints)
                break;
            case "pmacadam@dioxitek.com.ar":
                setRe(blueprints)
                break;
            default:
                setRe(blueprints)
        }
    }

    const data = {
        'estado': 'arevisarku',
        sector,
        fecha: moment(fecha).format("YYYY-MM-DD"),
        link
      }

    const guardarBlueprint = () => {
        if(data.sector === '' || data.fecha === '' || data.link === '') {
            return Swal.fire('Todos los campos son obligatorios')
        }
        setCargando(true)
        try {
          axios.post(`${BASE_URL}/blueprints/guardarblueprint`, data, {
              headers: {
                  token
              }}).then((response) => {
              if(response.data.error) {
                  setCargando(false)
                  return
              } else {
                  toast.success("Agregado con éxito", {
                      style: {
                        color: "#fff",
                        background: "#a47df0"
                      }
                  })
                  setCargando(false)
                  setRe(response.data)
                  setFecha('')
                  setSector('')
                  setLink('')
              }
          });
      } catch (error) {
        setCargando(false)
      }
      }

    const enviar = (sector, estado) => {
        const actualizar = {
            sector,
            nombre: user.nombre,
            mail: user.usuario,
            estado
        }
        Swal.fire({
            title: 'Desea enviar el blueprint para revisar?',
            showCancelButton: true,
            confirmButtonText: 'Enviar',
            cancelButtonText: `Cancelar`,
            confirmButtonColor: '#21A321',
          }).then((result) => {
            if (result.isConfirmed) {
                setCargando(true)
                axios.put(`${BASE_URL}/blueprints/actualizarblueprint`, actualizar)
                .then((response) => {
                    if(response.data.error) {
                        setCargando(false)
                        toast.error(response.data.msg)
                        return
                    } else {
                        toast.success("Enviado con éxito", {
                            style: {
                              color: "#fff",
                              background: "#a47df0"
                            }
                        })
                        setCargando(false)
                        setBlueprints(response.data)
                        chequearUsuario()
                    }
                });
            } 
          })
    }

    const confirmarDoc = (id) => {
        Swal.fire({
            title: 'Confirma el documento como finalizado?',
            showCancelButton: true,
            confirmButtonText: 'Enviar',
            cancelButtonText: `Cancelar`,
            confirmButtonColor: '#21A321',
          }).then((result) => {
            if (result.isConfirmed) {
                setCargando(true)
                    try {
                    axios.put(`${BASE_URL}/blueprints/confirmarblueprint`, {id}, {
                        headers: {
                            token
                        }}).then((response) => {
                        if(response.data.error) {
                            setCargando(false)
                            return
                        } else {
                            toast.success("Blueprint confirmado", {
                                style: {
                                    color: "#fff",
                                    background: "#a47df0"
                                }
                            })
                            setCargando(false)
                            setRe(response.data)
                            setFecha('')
                            setSector('')
                            setLink('')
                        }
                    });
                } catch (error) {
                    setCargando(false)
                }
            } 
          })
    }

    useEffect(() => {
        obtenerBlueprints()
        obtenerSectores()
    }, [])

    useEffect(() => {
        chequearUsuario()
    }, [blueprints])

  return (
    <div className='contenedorContenido'>
    <Toaster />
        <div className='encabezadoFlex'>
            <h3>Blueprints</h3>
            {
                user.tipo_usuario === 'master' ? 
                    <button type="button" className="btn btn-success btn-agregar" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Agregar
                    </button>
                :
                    ''
            }
        </div>
        {/* <p className='nohayeventos'>No hay relevamientos cargados</p> */}
      <div className='contenedorListado'>
        <div className='encabezadoListado shadow'>
            <div className='numeroItem'>
                <p className='fw-bolder'>#</p>
            </div>
            <div className='nombreItem'>
                <p className='fw-bolder'>Sector</p>
            </div>
            <div className='contenedorNombreEstado'>
                <div className='btnItem'>
                    <p className='fw-bolder'>Documento</p>
                </div>
                <div className='estadoItem'>
                    <p className='fw-bolder'>Estado</p>
                </div>
            </div>
            <div className='accionItem'>
                <p className='fw-bolder'>Acción</p>
            </div>
        </div>

        {
            cargando ? 
                <div className='contenedorCargando'>
                    <div className="spinner-border text-warning" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            :
                re.length > 0 ?
                    re.map((rel, idx) => (
                        <div key={rel.id} className='itemListado shadow-sm'>
                            <div className='numeroItem'>
                                <p>{idx+1}</p>
                            </div>
                            <div className='nombreItem'>
                                <p>{rel.sector}</p>
                            </div>
                            <div className='contenedorNombreEstado'>
                                <div className='btnItem'>
                                    <a href={rel.link} target="_blank" rel="noreferrer"><button className='btn-documento'><i className="bi bi-box-arrow-up-right me-1"></i> Ver blueprint</button></a>
                                </div>
                                <div className='estadoItem'>
                                    {rel.estado === "arevisargna" && user.tipo_usuario === "master" ? 
                                        <p className='aRevisarKU'><i className="bi bi-exclamation-circle-fill"></i> A revisar GNA</p> 
                                    : 
                                        rel.estado === "arevisargna" && user.tipo_usuario !== "master" ? 
                                            <p className='respondido'><i className="bi bi-exclamation-circle-fill"></i> A revisar GNA</p> 
                                        :
                                        rel.estado === "arevisarku" && user.tipo_usuario === "master" ? 
                                            <p className='respondido'><i className="bi bi-exclamation-circle-fill"></i> A revisar KU</p> 
                                        :
                                        rel.estado === "arevisarku" && user.tipo_usuario !== "master" ? 
                                            <p className='aRevisarKU'><i className="bi bi-exclamation-circle-fill"></i> A revisar KU</p> 
                                        :
                                            <p className='respondido'><i className="bi bi-exclamation-circle-fill"></i> Confirmado</p>
                                    }
                                </div>
                            </div>
                            <div className='accionItem'>
                                {
                                    user.tipo_usuario === 'master' ?
                                        <button className='btn btn-warning btn-sm' disabled={rel.estado === 'arevisarku' || rel.estado === 'confirmado' ? true : false} onClick={()=>enviar(rel.sector, rel.estado)}>Enviar</button>
                                    :
                                        <button className='btn btn-warning btn-sm' disabled={rel.estado === 'arevisargna' || rel.estado === 'confirmado' ? true : false} onClick={()=>enviar(rel.sector, rel.estado)}>Enviar</button>
                                }
                                <button className='btn-confirmar' onClick={()=> confirmarDoc(rel.id)}>Confirmar</button>
                            </div>
                        </div>
                    ))
                :
                    <p className='nohayeventos'>No hay blueprints cargados</p>
        } 
      </div>

        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Agregar Blueprint</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form>
                        <div className="mb-3">
                        <select className="form-select" onChange={(e) => setSector(e.target.value)}>
                            <option defaultValue={sector}>Sector</option>
                            {sectores.length > 0 ?
                            sectores.map(sector => (
                                <option value={sector.sector} key={sector.id}>{sector.sector}</option>
                            ))
                            : ''
                            }
                        </select>
                        </div>
                        <div className="mb-3">
                            <input type="date" className="form-control" value={fecha} onChange={(e) => setFecha(e.target.value)} placeholder='Fecha' />
                        </div>
                        <div className="mb-3">
                            <input type="text" className="form-control" placeholder='Link' value={link} onChange={(e) => setLink(e.target.value)} />
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
                    <button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={guardarBlueprint}>Guardar</button>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Blueprints
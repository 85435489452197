import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { Context } from '../../store/AppContext'
import axios from 'axios'
import moment from 'moment';
import { BASE_URL } from '../../utils'
import './Minutas.css'
import toast, { Toaster } from 'react-hot-toast';
import Cargando from '../../components/Cargando/Cargando';

const Minutas = () => {
    const {user} = useContext(Context)
    const [minutas, setMinutas] = useState([])
    const [min, setMin] = useState([])
    const [cargando, setCargando] = useState(true)
    const [sector, setSector] = useState('')
    const [fecha, setFecha] = useState('')
    const [minuta, setMinuta] = useState('')
    const token = localStorage.getItem('usuario')

    const obtenerMinutas = async () => {
        const response = await axios.get(`${BASE_URL}/minutas`);
        setCargando(false)
        setMinutas(response.data)
    }

    const chequearUsuario = () => {
        switch (user.usuario) {
            case "abortolotti@dioxitek.com.ar":
                setMin(minutas.filter(rele => rele.documento === "Contabilidad" || rele.documento === "Presupuesto"))
                break;
            case "pgonzalez@dioxitek.com.ar":
                setMin(minutas.filter(rele => rele.documento === "Contabilidad"))
                break;
            case "sisasi@dioxitek.com.ar":
                setMin(minutas.filter(rele => rele.documento === "Presupuesto"))
                break;
            // case "ncevela@dioxitek.com.ar":
            //     setMin(minutas.filter(rele => rele.documento === "Compras" || rele.documento === "Tesorería/Finanzas" || rele.documento === "Inventario y Patrimonio" || rele.documento === "Facturación" || rele.documento === "Cuentas a Pagar" || rele.documento === "Administración CBA" || rele.documento === "Tesorería CBA" || rele.documento === "Compras CBA"))
            //     break;
            case "cvazquez@dioxitek.com.ar":
                setMin(minutas.filter(rele => rele.documento === "Compras"))
                break;
            case "dzubiaurre@dioxitek.com.ar":
                setMin(minutas.filter(rele => rele.documento === "Tesorería/Finanzas"))
                break;
            case "mcardozo@dioxitek.com.ar":
                setMin(minutas.filter(rele => rele.documento === "Inventario" || rele.documento === "Patrimonio" ))
                break;
            case "nlofreda@dioxitek.com.ar":
                setMin(minutas.filter(rele => rele.documento === "Facturación"))
                break;
            case "cgarcia@dioxitek.com.ar":
                setMin(minutas.filter(rele => rele.documento === "Administración CBA" || rele.documento === "Tesorería CBA" || rele.documento === "Compras CBA" || rele.documento === "Compras" || rele.documento === "Tesorería/Finanzas"))
                break;
            case "ssalgado@dioxitek.com.ar":
                setMin(minutas.filter(rele => rele.documento === "Tesorería CBA" || rele.documento === "Tesorería/Finanzas"))
                break;
            case "amoreno@dioxitek.com.ar":
                setMin(minutas.filter(rele => rele.documento === "Compras CBA" || rele.documento === "Compras"))
                break;
            case "dberbach@dioxitek.com.ar":
                setMin(minutas)
                break;
            case "dsureda@dioxitek.com.ar":
                setMin(minutas)
                break;
            case "pnoguera@dioxitek.com.ar":
                setMin(minutas)
                break;
            case "lcernadas@dioxitek.com.ar":
                setMin(minutas)
                break;
            case "ssavarese@dioxitek.com.ar":
                setMin(minutas)
                break;
            case "cesarvalleiva@gmail.com":
                setMin(minutas)
                break;
            case "cjimenez@dioxitek.com.ar":
                setMin(minutas)
                break;
            case "pmacadam@dioxitek.com.ar":
                setMin(minutas)
                break;
            default:
                setMin(minutas)
        }
    }

    const data = {
        documento: sector,
        fecha,
        minuta,
        estado: 1
    }

    const guardarMinuta = () => {
        setCargando(true)
        try {
          axios.post(`${BASE_URL}/minutas/guardarminuta`, data, {
              headers: {
                  token
              }}).then((response) => {
              if(response.data.error) {
                  setCargando(false)
                  // setError(response.data.msg)
                  return
              } else {
                  toast.success("Agregada con éxito", {
                      style: {
                        color: "#fff",
                        background: "#a47df0"
                      }
                  })
                  setCargando(false)
                  setMinutas(response.data)
                  setSector('')
                  setFecha('')
                  setMinuta('')
              }
          });
      } catch (error) {
        setCargando(false)
      }
      }

    useEffect(() => {
        obtenerMinutas()
    }, [])

    useEffect(() => {
        chequearUsuario()
    }, [minutas])

  return (
    <div className='contenedorContenido'>
    <Toaster />
        <div className='encabezadoFlex'>
            <h3>Minutas</h3>
            {
                user.tipo_usuario === 'master' ? 
                    <button type="button" className="btn btn-success btn-agregar" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Agregar
                    </button>
                :
                    ''
            }
        </div>
        <div className='contenedorListado'>
            <div className='encabezadoListado shadow'>
                <div className='numeroItem'>
                    <p className='fw-bolder'>#</p>
                </div>
                <div className='contenedoDocumentoEstado'>
                    <div className='tituloDocumento'>
                        <p className='fw-bolder'>Sector</p>
                    </div>
                    <div className='fechaDocumento'>
                        <p className='fw-bolder'>Fecha</p>
                    </div>
                </div>
                <div className='accionItem'>
                    <p className='fw-bolder'>Acción</p>
                </div>
            </div>
        </div>

        {
            cargando ?
                // <Cargando />
                <div className='contenedorCargando'>
                    <div className="spinner-border text-warning" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            :
                minutas.length > 0 ?
                    min.length > 0 ?
                        min.map((min, idx) => (
                            <div key={min.id} className='itemListado shadow-sm'>
                                <div className='numeroItem'>
                                    <p>{idx+1}</p>
                                </div>
                                <div className='contenedoDocumentoEstado'>
                                    <div className='tituloDocumento'>
                                        <p>{min.documento}</p>
                                    </div>
                                    <div className='fechaDocumento'>
                                        {moment(min.fecha).format("DD/MM/YYYY")}
                                    </div>
                                </div>
                                <div className='accionItem'>
                                    <a href={min.minuta} target="_blank"><button className='btn-documento'><i className="bi bi-box-arrow-up-right me-1"></i> Ver minuta</button></a>
                                </div>
                            </div>
                        ))
                    :
                        '' 
                :
                    <p className='nohayeventos'>No hay minutas cargadas</p>
        }

        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Agregar Minuta</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form>
                        <div className="mb-3">
                            <input type="text" className="form-control" value={sector} onChange={(e) => setSector(e.target.value)} placeholder='Sector' />
                        </div>
                        <div className="mb-3">
                            <input type="date" className="form-control" value={fecha} onChange={(e) => setFecha(e.target.value)} placeholder='Fecha' />
                        </div>
                        <div className="mb-3">
                            <input type="text" className="form-control" value={minuta} onChange={(e) => setMinuta(e.target.value)} placeholder='Link' />
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
                    <button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={guardarMinuta}>Guardar</button>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Minutas
import React, { useContext } from 'react'
import { Context } from '../../store/AppContext'
import moment from 'moment'
import './CardFecha.css'

const CardFecha = (props) => {
  const {ancho, user} = useContext(Context)
  const {id, fecha, sector, link, tipo_fecha, invite} = props

  const editarFecha = (id) => {
    console.log(id)
  }

  return (
    <div className='cardFecha shadow'>
      <div className='contenedorIconoEvento'>
        <div className='d-flex'>
          <i className="bi bi-clipboard2-check"></i>
          <p>{ancho < 600 && tipo_fecha === "Business Blueprint" ? "BBP" : tipo_fecha}</p>
        </div>
        {
          user.tipo_usuario === 'master' ?
            <button type="button" className="btn btn-warning btn-editar" data-bs-toggle="modal" data-bs-target={`#agregarEvento${id}`}>
              <i className="bi bi-pencil-square editar" onClick={() => editarFecha(id)}></i>
            </button>
          :
            ''
        }
      </div>
      <p className='fecha text-danger'> {fecha}</p>
      <h2>{sector}</h2>
      {link !== "" ? <a href={link} target="_blank" rel="noreferrer"><button className='btn btn-sm btn-documento mt-2'>Ver documento</button> </a>: ''}
      {invite !== "" ? 
        <p>{invite !== "" ? <a href={invite} target="_blank" rel="noreferrer"><button className='btn btn-sm btn-invite mt-2'>Link de la reunión</button> </a>: ''}</p>
      : ''}
    
      <div className="modal fade" id={`agregarEvento${id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Editar Evento</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {/* <form> */}
                <div className="mb-3">
                    <input type="text" className="form-control" placeholder='Fecha' value={fecha} />
                </div>
                <div className="mb-3">
                    <input type="text" className="form-control" placeholder='Link' value={sector}  />
                </div>
              {/* </form> */}
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
                <button type="submit" className="btn btn-success" data-bs-dismiss="modal">Guardar</button>
            </div>
            </div>
        </div>
      </div>
   
    </div>
  )
}

export default CardFecha
import React, { useContext } from 'react'
import { Context } from '../../store/AppContext'
import './Etapas.css'

const Etapas = () => {
    const {user} = useContext(Context)
  return (
    <div className='contenedorContenido'>
        <div className='contenedorEtapas'>
            <div className='gantt'>
                <h3>Etapas del proyecto</h3>
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <div className="tituloGantt"><p className='negrita'>Business Blueprints</p> <p>02/08/2022 - 30/11/2022</p></div>
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <ul>
                                    <li>- Relevamientos</li>
                                    <li>- Diseño de modelo y Redacción de BBP</li>
                                    <li>- Re-ingeniería de datos</li>
                                    <li>- Firma de Blueprints (realizadas el 06/01/2023)</li>
                                    {/* <li>- Firma de Blueprints <span className='firmasbbp'>(el proceso de firmas de BBP finalizó el 06/01/2023)</span></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <div className="tituloGantt"><p className='negrita'>Construcción</p> <p>16/01/2023 - 28/02/2023</p></div>
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <ul>
                                    <li>- Infraestructura</li>
                                    <li>- Construcción funcional Backoffice</li>
                                    <li>- Construcción Técnica</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <div className="tituloGantt"><p className='negrita'>Preparación Final</p> <p>30/06/2023 - 15/08/2023</p></div>
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <ul>
                                    <li>- Migración de Datos Maestros</li>
                                    <li>- Creación de Grupos de Artículos</li>
                                    <li>- Artículos</li>
                                    <li>- Creación de Grupos de Socios de Negocios</li>
                                    <li>- Socios de Negocios</li>
                                    <li>- Depósitos</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourth" aria-expanded="false" aria-controls="collapseThree">
                            <div className="tituloGantt"><p className='negrita'>Capacitación y Pruebas</p> <p>15/08/2023 - 15/10/2023</p></div>
                            </button>
                        </h2>
                        <div id="collapseFourth" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <ul>
                                    <li>- Capacitación</li>
                                    <li>- Pruebas</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFiveth" aria-expanded="false" aria-controls="collapseThree">
                            <div className="tituloGantt"><p className='negrita'>GO LIVE</p> <p>31/10/2023</p></div>
                            </button>
                        </h2>
                        <div id="collapseFiveth" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <ul>
                                    <li>- Go Live</li>
                                    <li>- Soporte Post Go Live</li>
                                </ul>
                            </div>
                        </div>
                </div>
            </div>
            </div>
            <div className='kpis'>
                <h3>Avance</h3>
                <div className='contenedorKpis'>
                    <div className='kpi shadow'>
                        <div className='iconoAvance'>
                            <i className="bi bi-journal-check"></i>
                        </div>
                        <div className='numeroYtextoAvance'>
                            <p className='cantidadAvance text-danger'>100<span className='porcentajeAvance'>%</span></p>
                            <p className='tituloKpi'>Relevamientos</p>
                        </div>
                    </div>
                    <div className='kpi shadow'>
                        <div className='iconoAvance'>
                            <i className="bi bi-file-earmark-check"></i>
                        </div>
                        <div className='numeroYtextoAvance'>
                            <p className='cantidadAvance text-danger'>100<span className='porcentajeAvance'>%</span></p>
                            <p className='tituloKpi'>Business Blueprints</p>
                        </div>
                    </div>
                    <div className='kpi shadow'>
                        <div className='iconoAvance'>
                            <i className="bi bi-book"></i>
                        </div>
                        <div className='numeroYtextoAvance'>
                            <p className='cantidadAvance text-danger'>0<span className='porcentajeAvance'>%</span></p>
                            <p className='tituloKpi'>Casos de Prueba</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Etapas
import React, { useContext } from 'react'
import { Context } from '../../store/AppContext'
import './Casosdeprueba.css'

const Casosdeprueba = () => {
    const {user} = useContext(Context)
  return (
    <div className='contenedorContenido'>
        <div className='encabezadoFlex'>
            <h3>Casos de Prueba</h3>
            {
                user.tipo_usuario === 'master' ? 
                    <button type="button" className="btn btn-success btn-agregar" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Agregar
                    </button>
                :
                    ''
            }
        </div>
        <p className='nohayeventos'>No hay casos de prueba cargados</p>
    </div>
  )
}

export default Casosdeprueba
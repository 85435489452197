import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Context } from '../../store/AppContext'
import './Menu.css'

const Menu = () => {
  const {pathname} = useLocation()
  const {ancho, handleMenu} = useContext(Context)

  return (
    <div className='menu'>
      <div className='titulo-menu'>
        <p>menu</p>
      </div>
        <ul>
            <Link to="/home" onClick={ancho < 1200 ? handleMenu : ''}><li className={pathname === '/home' ? "active" : ''}><i className="bi bi-calendar-check me-2"></i>Agenda</li></Link>
            {/* <Link to="/interfaz" onClick={ancho < 1200 ? handleMenu : ''}><li className={pathname === '/interfaz' ? "active" : ''}><i className="bi bi-arrow-left-right me-2"></i>Interfaz</li></Link> */}
            <Link to="/etapas" onClick={ancho < 1200 ? handleMenu : ''}><li className={pathname === '/etapas' ? "active" : ''}><i className="bi bi-bar-chart-steps me-2"></i>Etapas del proyecto</li></Link>
            <Link to="/minutas" onClick={ancho < 1200 ? handleMenu : ''}><li className={pathname === '/minutas' ? "active" : ''}><i className="bi bi-journal-check me-2"></i>Minutas</li></Link>
            <Link to="/relevamientos" onClick={ancho < 1200 ? handleMenu : ''}><li className={pathname === '/relevamientos' ? "active" : ''}><i className="bi bi-journal-check me-2"></i>Relevamientos</li></Link>
            <Link to="/blueprints" onClick={ancho < 1200 ? handleMenu : ''}><li className={pathname === '/blueprints' ? "active" : ''}><i className="bi bi-file-earmark-check me-2"></i>Business Blueprint</li></Link>
            <Link to="/pruebas" onClick={ancho < 1200 ? handleMenu : ''}><li className={pathname === '/pruebas' ? "active" : ''}><i className="bi bi-book me-2"></i>Casos de prueba</li></Link>
            <Link to="/equipo" onClick={ancho < 1200 ? handleMenu : ''}><li className={pathname === '/equipo' ? "active" : ''}><i className="bi bi-people me-2"></i>Equipo</li></Link>
        </ul>
        {/* <div className='titulo-menu titulo-menu-admin'>
          <p>admin</p>
        </div>
        <ul>
            <Link to="/home" onClick={ancho < 1200 ? handleMenu : ''}><li className={pathname === '/home' ? "active" : ''}><i className="bi bi-speedometer2 me-2"></i>Agenda</li></Link>
            <Link to="/equipo" onClick={ancho < 1200 ? handleMenu : ''}><li className={pathname === '/equipo' ? "active" : ''}><i className="bi bi-people me-2"></i>Equipo</li></Link>
        </ul> */}
    </div>
  )
}

export default Menu
import { createContext, useState, useEffect } from "react";
import jwt from 'jwt-decode';

export const Context = createContext(null)

const AppContext = ({children}) => {
    const [user, setUser] = useState({})
    const [sideBar, setSidebar] = useState(true);
    const [ancho,] = useState(window.innerWidth);

    const handleMenu = () => {
        setSidebar(!sideBar);
    };

    useEffect(() => {
      if(localStorage.getItem('usuario') !== null){
          let usuario = localStorage.getItem('usuario')
          setUser(jwt(usuario));
      }
  }, []);

  return (
    <Context.Provider value={{user, setUser, sideBar, setSidebar, handleMenu, ancho}}>
        {children}
    </Context.Provider>
  )
}

export default AppContext
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import CardFecha from '../../components/CardFecha'
import { Context } from '../../store/AppContext'
import moment from 'moment';
import { BASE_URL } from '../../utils'
import toast, { Toaster } from 'react-hot-toast';
import './Home.css'
import Cargando from '../../components/Cargando/Cargando';

const Home = () => {
  const {user} = useContext(Context)
  const [eventos, setEventos] = useState([])
  const [tipo_fecha, setTipo_fecha] = useState('')
  const [sectores, setSectores] = useState([])
  const [sector, setSector] = useState('')
  const [fechas, setFechas] = useState([])
  const [fecha, setFecha] = useState('')
  const [link, setLink] = useState('')
  const [fechasusuario, setFechasusuario] = useState([])
  const [cargando, setCargando] = useState(false)
  const token = localStorage.getItem('usuario')


  const obtenerSectores = async () => {
    const response = await axios.get(`${BASE_URL}/sectores`)
    setSectores(response.data)
  }

  const obtenerEventos = async () => {
    const response = await axios.get(`${BASE_URL}/eventos`)
    setEventos(response.data)
  }

  const obtenerFechas = async () => {
    setCargando(true)
    const response = await axios.get(`${BASE_URL}/fechas`)
    setFechas(response.data)
    setCargando(false)
  }

  const chequearUsuario = () => {
    switch (user.usuario) {
        case "abortolotti@dioxitek.com.ar":
            setFechasusuario(fechas.filter(fecha => fecha.sector === "Contabilidad" || fecha.sector === "Presupuesto"))
            break;
        case "pgonzalez@dioxitek.com.ar":
            setFechasusuario(fechas.filter(fecha => fecha.sector === "Contabilidad"))
            break;
        case "sisasi@dioxitek.com.ar":
            setFechasusuario(fechas.filter(fecha => fecha.sector === "Presupuesto"))
            break;
        case "ncevela@dioxitek.com.ar":
            setFechasusuario(fechas.filter(fecha => fecha.sector === "Compras" || fecha.sector === "Tesorería/Finanzas" || fecha.sector === "Inventario y Patrimonio" || fecha.sector === "Facturación" || fecha.sector === "Cuentas a Pagar" || fecha.sector === "Administración CBA" || fecha.sector === "Tesorería CBA" || fecha.sector === "Compras CBA"))
            break;
        case "dfernandez@dioxitek.com.ar":
          setFechasusuario(fechas.filter(fecha => fecha.sector === "Compras"))
          break;
        case "dzubiaurre@dioxitek.com.ar":
            setFechasusuario(fechas.filter(fecha => fecha.sector === "Tesorería/Finanzas"))
            break;
        case "mcardozo@dioxitek.com.ar":
            setFechasusuario(fechas.filter(fecha => fecha.sector === "Inventario" || fecha.sector === "Patrimonio" ))
            break;
        case "nlofreda@dioxitek.com.ar":
            setFechasusuario(fechas.filter(fecha => fecha.sector === "Facturación"))
            break;
        case "cgarcia@dioxitek.com.ar":
            setFechasusuario(fechas.filter(fecha => fecha.sector === "Administración CBA" || fecha.sector === "Tesorería CBA" || fecha.sector === "Compras CBA"))
            break;
        case "ssalgado@dioxitek.com.ar":
            setFechasusuario(fechas.filter(fecha => fecha.sector === "Tesorería CBA"))
            break;
        case "amoreno@dioxitek.com.ar":
            setFechasusuario(fechas.filter(fecha => fecha.sector === "Compras CBA"))
            break;
        case "agruben@dioxitek.com.ar":
            setFechasusuario(fechas)
            break;
        case "dsureda@dioxitek.com.ar":
            setFechasusuario(fechas)
            break;
        case "pnoguera@dioxitek.com.ar":
            setFechasusuario(fechas)
            break;
        case "lcernadas@dioxitek.com.ar":
            setFechasusuario(fechas)
            break;
        case "ssavarese@dioxitek.com.ar":
            setFechasusuario(fechas)
            break;
        case "cesarvalleiva@gmail.com":
            setFechasusuario(fechas)
            break;
        case "cjimenez@dioxitek.com.ar":
          setFechasusuario(fechas)
          break;
        case "fperalta@dioxitek.com.ar":
          setFechasusuario(fechas)
          break;
        default:
            setFechasusuario(fechas)
    }
}

  const data = {
    tipo_fecha,
    sector,
    fecha: moment(fecha).format("DD/MM"),
    link
  }

  const guardarEvento = () => {
    setCargando(true)
    try {
      axios.post(`${BASE_URL}/fechas/guardarfecha`, data, {
          headers: {
              token
          }}).then((response) => {
          if(response.data.error) {
              setCargando(false)
              // setError(response.data.msg)
              return
          } else {
              toast.success("Agregado con éxito", {
                  style: {
                    color: "#fff",
                    background: "#a47df0"
                  }
              })
              setCargando(false)
              setFechas(response.data)
              setFecha('')
              setLink('')
          }
      });
  } catch (error) {
    setCargando(false)
  }
  }

  useEffect(() => {
    obtenerSectores()
    obtenerEventos()
    obtenerFechas()
  }, [])

  useEffect(() => {
    chequearUsuario()
  }, [fechas])

  return (
    <div className='contenedorContenido'>
    <Toaster />
      <div className='encabezadoFlex'>
        <h3>Próximos eventos</h3>
        {
            user.tipo_usuario === 'master' ? 
                <button type="button" className="btn btn-success btn-agregar" data-bs-toggle="modal" data-bs-target="#agregarEvento">
                    Agregar
                </button>
            :
                ''
        }
      </div>
      <div className='contenedorCardsFechas'>
        {
          cargando ? 
            <Cargando />
          :
            fechasusuario.length > 0 ? 
              fechasusuario.map(fecha => (
                <div className='contenedorCardsFecha' key={fecha.id}>
                  <CardFecha {...fecha} />
                </div>
              ))
            :
            <p className='nohayeventos'>No hay eventos cargados</p>
        }
      </div>

      <div className="modal fade" id="agregarEvento" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Agregar Evento</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  {/* <form> */}
                    <div className="mb-3">
                      <select className="form-select" onChange={(e) => setTipo_fecha(e.target.value)}>
                        <option defaultValue={tipo_fecha}>Evento</option>
                        {eventos.length > 0 ?
                          eventos.map(evento => (
                            <option value={evento.evento} key={evento.id}>{evento.evento}</option>
                          ))
                          : ''
                        }
                      </select>
                    </div>
                    <div className="mb-3">
                      <select className="form-select" onChange={(e) => setSector(e.target.value)}>
                        <option defaultValue={sector}>Sector</option>
                        {sectores.length > 0 ?
                          sectores.map(sector => (
                            <option value={sector.sector} key={sector.id}>{sector.sector}</option>
                          ))
                          : ''
                        }
                      </select>
                    </div>
                    <div className="mb-3">
                        <input type="date" className="form-control" placeholder='Fecha' value={fecha} onChange={(e) => setFecha(e.target.value)} />
                    </div>
                    <div className="mb-3">
                        <input type="text" className="form-control" placeholder='Link' value={link} onChange={(e) => setLink(e.target.value)} />
                    </div>
                  {/* </form> */}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
                    <button type="submit" onClick={guardarEvento} className="btn btn-success" data-bs-dismiss="modal">Guardar</button>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Home
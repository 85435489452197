import React from 'react'
import CardEquipo from '../../components/CardEquipo/CardEquipo'
import User from '../../img/user.png'
import './Equipo.css'

const Equipo = () => {
  return (
    <div className='contenedorContenido'>
        <div className='contenedorEquipo'>
            {/* <CardEquipo /> */}
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Pablo Macadam</h4>
                <h5>Sponsor</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Diego Berbach</h4>
                <h5>Líder de proyecto</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Dolores Sureda</h4>
                <h5>Equipo implementador</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Patricia Noguera</h4>
                <h5>Equipo implementador</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Vacante</h4>
                <h5>Equipo implementador</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Ariel Bortolotti</h4>
                <h5>Contabilidad</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Diego Berbach</h4>
                <h5>Administración</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Sebastián Savarese</h4>
                <h5>TIC</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Pablo González</h4>
                <h5>Contabilidad</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Soledad Isasi</h4>
                <h5>Presupuesto</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Diego Berbach</h4>
                <h5>Compras</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Diego Zubiaurre</h4>
                <h5>Tesorería / Finanzas</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Mónica Cardozo</h4>
                <h5>Inventario y Patrimonio</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Nazareth Lofreda</h4>
                <h5>Facturación</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Dolores Sureda</h4>
                <h5>Cuentas a Pagar</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Vacante</h4>
                <h5>Admin. CBA.</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Sandra Salgado</h4>
                <h5>Tesorería CBA.</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Adriana Moreno</h4>
                <h5>Compras CBA.</h5>
            </div>
            {/* <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Pablo González</h4>
                <h5>Contabilidad</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Soledad Isasi</h4>
                <h5>Presupuesto</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Cecilia Vazquez</h4>
                <h5>Compras</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Diego Zubiaurre</h4>
                <h5>Tesorería / Finanzas</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Mónica Cardozo</h4>
                <h5>Inventario y Patrimonio</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Nazareth Lofreda</h4>
                <h5>Facturación</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Dolores Sureda</h4>
                <h5>Cuentas a Pagar</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Corina García</h4>
                <h5>Administración CBA</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Sandra Salgado</h4>
                <h5>Tesorería CBA</h5>
            </div>
            <div className='cardEquipo'>
                <img src={User} alt="Usuario" />
                <h4>Adriana Moreno</h4>
                <h5>Compras CBA</h5>
            </div> */}
        </div>
    </div>
  )
}

export default Equipo